<template>
  <div>
    <frequencyDistributionForMicrobe :chartData="chartData" :type="type" title="유해균"></frequencyDistributionForMicrobe>
  </div>
</template>

<script>
import frequencyDistributionForMicrobe from './frequencyDistributionForMicrobe'

export default {
  name: 'HarmfulBacteria', // 유해균
  components: { frequencyDistributionForMicrobe },
  data() {
    return {
      type : "species",
      // 요청 후 차트 콤포넌트에서 파라미터를 날림
      chartData : {
        ["Clostridioides Difficile"]: {
          genus : "Clostridioides",
          species : "difficile"
        },
        ["Staphylococcus Aureus"]: {
          genus : "Staphylococcus",
          species : "aureus"
        },
        Salmonella : 'Salmonella',
        Shigella : 'Shigella',
        Fusobacterium : 'Fusobacterium', //Fusobacteria --> phylum level
        ["Cronobacter Sakazakii"]: {
          genus : "Cronobacter",
          species : "sakazakii"
        }
      },
    }

  },
  methods: {
  },
  mounted() {
  },
  created () {
  },
  computed : {
  }

}
</script>

<style scoped>
.vCard {
 border: 1px solid white;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-text-field >>> label {
  font-size: 13px;
}
</style>

